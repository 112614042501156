.wordBox{
    display: inline-block;
    min-width: 150px;
    min-height: 60px;
    background-color: #ccc;
    color:black;
    padding: 5px 10px;
    margin: 0px 10px 10px 0px;
    border-radius: 7px;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.smallLine{
    font-size: 10px;
}

@media (prefers-color-scheme: dark) {
.wordBox{
    background-color: #222;
    color:#fff;
}

}

