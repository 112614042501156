* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
 
}

a {
  color: inherit;
  text-decoration: none;
}
html {
  background-color: white;
}
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
    background-color: black;

  }
}
