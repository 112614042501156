.button
{
    font-size: 25px;
    float: right;
    background-color: #ddd;
    margin-right: 15px;
    margin-top: 5px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 10px;
}
.button:hover{
  background-color: #bbb;
}
@media (prefers-color-scheme: dark) {
.button{
    background-color: #222;
}
.button:hover{
  background-color: #555;
}
}