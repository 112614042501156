.header{
    width: 100%;
    margin-top: 5px;
    margin-bottom: 3px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.titleBox{
    display: inline-block;
    width: calc(100% - 100px);
}
.title{
    font-size: 35px;
    /* display: inline-block; */
    /* left: calc(50% - 150px); */
    /* position: relative; */
    text-align: center;
}
