

/* The Modal (background) */
.modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modalContent {
  position: relative;
  background-color: #777;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #ee2222;
  text-decoration: none;
  cursor: pointer;
}

.modalHeader {
  padding: 2px 16px;
  background-color: #555;
  color: white;
}

.modalBody {
  padding: 2px 16px;
  margin: 10px 0px;
}

.modalFooter {
  padding: 2px 16px;
  background-color: #555;
  color: white;
  height: 40px;
}
.btnRight{
  float: right;

}
.btnCancel{
  float: right;
}
.btnRemove{
    float: left;
    margin-right: 10px;
    background-color: rgb(187, 136, 136)
}
.btnSave{
  float:left;
  background-color: #8b8

}
.footerButton{
  padding: 5px;
  margin-top: 3px;
  cursor: pointer;
}

.textField
{
  width: 100%;
  height: 50px;
}
.fieldNewWord
{

}



.fieldWordDescription
{
}
