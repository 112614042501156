.StartTraining{
    font-size: 25px;
    background-color: #ddd;
    display: inline-block;
    margin-right: 15px;
    margin-top: 5px;
    padding: 5px 10px;
    border-radius: 10px;
    /* cursor: pointer; */
}
.StartTraining:hover,
.StartTraining:focus {
  background-color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

@media (prefers-color-scheme: dark) {
.StartTraining{
    background-color: #333;
}
.StartTraining:hover,
.StartTraining:focus {
  background-color: #555;
}

}



/* The Modal (background) */
.modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modalContent {
  position: relative;
  background-color: #777;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 90%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #ee2222;
  text-decoration: none;
  cursor: pointer;
}

.modalHeader {
  padding: 2px 16px;
  background-color: #555;
  color: white;
}

.modalBody {
  padding: 2px 16px;
  margin: 10px 0px;
}

.modalFooter {
  padding: 2px 16px;
  background-color: #555;
  color: white;
  height: 40px;
}
.btnCancel{
  float: right;
}
.btnSave{
  float:left;
  background-color: #8b8

}
.footerButton{
  padding: 5px;
  margin-top: 3px;
  cursor: pointer;
}

.detailTable{
  width: 100%;
}
.validateTable{
  
}
.textField
{
  padding-left: 4px;
  width: 100%;
  height: 50px;
}
.wordToLearn{
  /* display: block; */
  /* margin-right: 15px; */
}
.wordToLearnHelper{

}


.fieldNewWord
{

}

.fieldWordDescription
{
}
